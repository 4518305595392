import { Pipe, type PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safehtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, type: string = null): SafeHtml | SafeUrl | SafeResourceUrl{

    if(!value)
      return '';    

      switch (type) {
        case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        default: return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
